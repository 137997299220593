body {
  overflow-x: hidden;
  /* overflow-y: scroll;
  -ms-overflow-style: none; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(23, 235, 164);
  color: rgb(23, 235, 164);
  outline: 1px solid rgb(55, 99, 143);
} */
.p-datatable .p-datatable-header {
  background-color: white !important;
  padding: 5px !important;
}

.table-header {
  height: 50px;
}

.p-card .p-card-title {
  font-weight: 500 !important;
}

.p-m-0 {
  font-weight: 400 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.datestyle {
  width: "10%";
}

@media screen and (max-width: 500px) {
  .p-card {
    display: block;
  }
}

@media screen and (max-width: 900px) {
  .p-dialog {
    min-width: 100vw;
  }
}

/* @media screen and (max-width: 500px) {
  .p-toolbar {
    min-width: 100vw;
  }
} */
.layout-topbar {
  position: fixed;
  height: 80px;
  top: 0;
  left: 20;
  width: 100%;
  z-index: 110;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid var(--surface-d);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 35px;
}

.error-message {
  color: red;
}

.datascroller-demo .product-name {
  font-size: 1.5rem;
  font-weight: 700;
}

.datascroller-demo .product-description {
  margin: 0 0 1rem 0;
}

.datascroller-demo .product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.datascroller-demo .product-category {
  font-weight: 600;
  vertical-align: middle;
}

.datascroller-demo .product-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.datascroller-demo .product-item img {
  width: 150px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-right: 2rem;
}