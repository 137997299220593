/* TimelineDemo.css */

.timeline-demo .custom-marker {
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  z-index: 1;
}

.timeline-demo .p-timeline-event-content,
.timeline-demo .p-timeline-event-opposite {
  line-height: 1;
}

@media screen and (max-width: 960px) {
  .timeline-demo .customized-timeline .p-timeline-event:nth-child(even) {
    flex-direction: row !important;
  }
  .timeline-demo
    .customized-timeline
    .p-timeline-event:nth-child(even)
    .p-timeline-event-content {
    text-align: left !important;
  }
  .timeline-demo .customized-timeline .p-timeline-event-opposite {
    flex: 0;
  }
  .timeline-demo .customized-timeline .p-card {
    margin-top: 1rem;
  }
}
